import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { EndpointsService } from "./endpoints.service";

@Injectable()
export class EndpointsProvider {
    constructor(private endpointsService: EndpointsService) {}
    load(): Promise<boolean> {
        return this.endpointsService
            .load()
            .pipe(map((res) => !!res))
            .toPromise();
    }
}
