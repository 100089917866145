import { Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

export interface Range {
    min: number;
    max: number;
}

@Component({
    selector: "app-filter-input",
    templateUrl: "./filter-input.component.html",
    styleUrls: ["./filter-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FilterInputComponent),
            multi: true,
        },
    ],
})
export class FilterInputComponent implements ControlValueAccessor {
    @Input() label = "";
    @Input() identifier: string;
    @Input() separator = false;
    @Input() maxlength = 9999;
    @Input() value: Range;
    minValue = "";
    maxValue = "";
    minType: string;
    maxType: string;
    nf = new Intl.NumberFormat("nl-NL");

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    propagateChange = (_: Range): void => {};

    minChange(event: Event): void {
        this.minValue = this.checkLength((event.currentTarget as HTMLInputElement).value);
        this.valueChange();
    }

    maxChange(event: Event): void {
        this.maxValue = this.checkLength((event.currentTarget as HTMLInputElement).value);
        this.valueChange();
    }

    registerOnChange(fn: (range: Range) => void): void {
        this.propagateChange = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    registerOnTouched(): void {}

    valueChange(): void {
        const min = parseInt(this.removeNonNumeric(this.minValue), 10);
        const max = parseInt(this.removeNonNumeric(this.maxValue), 10);
        if (isNaN(min) && isNaN(max)) {
            this.propagateChange(null);
        } else {
            const range = {
                min: Math.min(min, max) || min,
                max: Math.max(min, max) || max,
            };
            this.propagateChange(range);
        }
    }

    writeValue(val: Range): void {
        this.minValue = val && val.min && !isNaN(val.min) ? val.min.toString(10) : "";
        this.maxValue = val && val.max && !isNaN(val.max) ? val.max.toString(10) : "";
    }

    removeNonNumeric(value: string): string {
        return value.replace(/\D/g, "");
    }

    checkLength(value: string): string {
        return this.maxlength != null ? value.substr(0, this.maxlength) : value;
    }
}
