import { Injectable } from "@angular/core";
import { MapService } from "@kadaster/generieke-geo-componenten-map";
import { Feature } from "ol";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Fill, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Arcering } from "../model/dto/Arcering";
import { Geometry } from "ol/geom";
import { WozObject } from "../model/dto/WozObject";

export interface LayerStyle {
    fill: string;
    stroke: string;
    zIndex: number;
}

@Injectable({
    providedIn: "root",
})
export class ArceringService {
    private bagFeatures: BehaviorSubject<Feature<Geometry>[]> = new BehaviorSubject([]);

    private kadastraleFeatures: BehaviorSubject<Feature<Geometry>[]> = new BehaviorSubject([]);

    private vboFeatures: BehaviorSubject<Feature<Geometry>[]> = new BehaviorSubject([]);

    private zoomLevel: BehaviorSubject<number> = new BehaviorSubject(null);

    private layers: Map<string, VectorLayer<VectorSource<Geometry>>> = new Map();

    private styles: Map<string, LayerStyle> = new Map([
        ["kadastraal", environment.kadastraleLayer],
        ["bag", environment.bagLayer],
        ["filter", environment.filterLayer],
    ]);

    constructor(private mapService: MapService) {}

    get arceringen(): Observable<Arcering> {
        return combineLatest([this.bagFeatures, this.kadastraleFeatures, this.vboFeatures, this.zoomLevel]).pipe(
            map(([bagFeatures, kadastraleFeatures, vboFeatures, zoom]) => ({
                bagFeatures,
                kadastraleFeatures,
                vboFeatures,
                zoom,
            }))
        );
    }

    addFeaturesToLayer(features: Feature<Geometry>[], layer: "kadastraal" | "bag" | "filter"): void {
        if (!this.layers.has(layer)) {
            this.layers.set(layer, this.createLayer(this.styles.get(layer)));
        }
        this.layers.get(layer)?.getSource().addFeatures(features);
    }

    clearLayer(layer: "kadastraal" | "bag" | "filter"): void {
        this.layers.get(layer)?.getSource().clear();
    }

    setFeatures(
        bagFeatures?: Feature<Geometry>[],
        vboFeatures?: Feature<Geometry>[],
        kadastraleFeatures?: Feature<Geometry>[],
        wozObject?: WozObject
    ): void {
        this.clearArcering();

        if (bagFeatures) {
            this.setBagFeatures(bagFeatures);
        }

        if (vboFeatures) {
            this.setVboFeatures(vboFeatures);
        }

        if (kadastraleFeatures && wozObject?.grondoppervlakte > 0) {
            this.setKadastraleFeature(kadastraleFeatures);
        }
    }

    setBagFeatures(features: Feature<Geometry>[], checkEmpty?: boolean): void {
        if (!checkEmpty || this.bagFeatures.getValue()?.length === 0) {
            this.bagFeatures.next(features);
        }
    }

    addToBagFeatures(features: Feature<Geometry>[]): void {
        const existing: Feature<Geometry>[] = this.bagFeatures.getValue();
        this.bagFeatures.next(existing ? [...existing, ...features] : features);
    }

    setKadastraleFeature(features: Feature<Geometry>[]): void {
        this.kadastraleFeatures.next(features);
    }

    setVboFeatures(features: Feature<Geometry>[]): void {
        this.vboFeatures.next(features);
    }

    setZoomlevel(zoom: number): void {
        this.zoomLevel.next(zoom);
    }

    clearArcering(): void {
        this.bagFeatures.next([]);
        this.kadastraleFeatures.next([]);
        this.vboFeatures.next([]);
    }

    private createLayer(layerStyle: LayerStyle): VectorLayer<VectorSource<Geometry>> {
        const style: Style = new Style();
        const fill = new Fill({ color: layerStyle.fill });
        const stroke = new Stroke({ color: layerStyle.stroke, width: 2 });
        style.setFill(fill);
        style.setStroke(stroke);
        style.setZIndex(layerStyle.zIndex);
        style.setImage(
            new CircleStyle({
                fill: fill,
                stroke: stroke,
                radius: 5,
            })
        );
        const source = new VectorSource();
        const vectorLayer = new VectorLayer({
            source,
            style,
        });
        vectorLayer.setZIndex(layerStyle.zIndex);
        const map = this.mapService.getMap(environment.mapName);
        vectorLayer.setMap(map);
        return vectorLayer;
    }
}
